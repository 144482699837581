import isMobile from './isMobile.js';
import scrollDown from './scrollDown.js';
import Flickity  from 'flickity';
import Cleave  from 'cleave.js';
import {fadeIn, fadeOut} from './fade.js';
import getAjax from './getAjax.js';
import serialize from './serialize.js';

window.jQuery = $;
require('jquery-nice-select');

$('select').niceSelect();

/*=======================================
=            BTN TOGGLE MENU            =
=======================================*/
var navbar = document.querySelector('.navbar');
var navbarMenuToggle = document.getElementById('navbar-menu-toggle');
(function(el, handler){
	el.attachEvent ? el.attachEvent('onclick', handler) : el.addEventListener('click', handler);
})( navbarMenuToggle, function(){
	if(this.style.display !== 'none'){
		var menu = document.getElementById(this.getAttribute('data-menuId'));
		if (menu.style.display != 'block'){
			fadeIn(menu)
		}
		else{
			fadeOut(menu)
		}
		if (navbar.classList) { 
			navbar.classList.toggle("open");
		} 
		else {
			var classes = navbar.className.split(" ");
			var i = classes.indexOf("open");
			if (i >= 0) {
				classes.splice(i, 1);
			}
			else {
				classes.push("open");
				navbar.className = classes.join(" "); 
			}
		}
	}
})
/*=====  End of BTN TOGGLE MENU  ======*/
/*================================================
=            BTN SCROLL DOWN ON CLICK            =
================================================*/
var navbarMenuItems = document.querySelectorAll('.navbar-menu-item-sd a, #arrow-up');
for (var i = 0; i < navbarMenuItems.length; i++){
	(function(el, handler){
		el.attachEvent ? el.attachEvent('onclick', handler) : el.addEventListener('click', handler);
	})(navbarMenuItems[i], function(e){

		var elem = document.querySelector(this.hash);
		if(elem){
			e.preventDefault();
			if(elem != navbar && window.innerWidth < 768){
				navbarMenuToggle.click();
			}
			
			scrollDown(elem, 300, 'easeOutQuad')
		}
	})
}
/*=====  End of BTN SCROLL DOWN ON CLICK  ======*/
/*=====================================
=            FORMAT FIELDS            =
=====================================*/
var cardNum = document.querySelectorAll('.card-num');
if(cardNum.length){
	new Cleave(cardNum[0], { 
		creditCard: true,
		onCreditCardTypeChanged: function (type) {
			var input = document.querySelector('#user_cartype-' + type);
			if(input){
				input.checked = true;				
			}
		}
	});
}
var inputVto = document.querySelectorAll('.input-vto');
if(inputVto.length){
	new Cleave(inputVto[0], {date: true, datePattern: ['m', 'y']});
}
/*=====  End of FORMAT FIELDS  ======*/
/*====================================
=            CONTACT FORM            =
====================================*/
var contactForm = document.querySelector('#contact-form');
if(contactForm){
	(function(el, handler){
		el.attachEvent ? el.attachEvent('onsubmit', handler) : el.addEventListener('submit', handler);
	})(
	contactForm,
	function(e){
		e.preventDefault();
		var fm = serialize(this);
		getAjax('/contacto?' + fm, data => {
			var msg = document.querySelector('.form-response' + (data.success ? '.success' : '.error'));	
			if(msg){
				msg.innerHTML = data.msg;
			}
		})
	});
}
/*=====  End of CONTACT FORM  ======*/
/*=======================================
=            NEWSLETTER FORM            =
=======================================*/
var newsletterForm = document.querySelector('#newsletter-form');
if(newsletterForm){
	(function(el, handler){
		el.attachEvent ? el.attachEvent('onsubmit', handler) : el.addEventListener('submit', handler);
	})(
	newsletterForm,
	function(e){
		e.preventDefault();
		var fm = serialize(this);
		getAjax('/newsletter?' + fm, data => {
			var msg = document.querySelector('.footer-form-response');	
			if(msg){
				msg.innerHTML = data.msg;
			}
		})
	});
}
/*=====  End of NEWSLETTER FORM  ======*/
/*================================
=            ARROW UP            =
================================*/
/*
var arrowUp = document.querySelector('#arrow-up');
(function(handler){
	var last_known_scroll_position = 0;
	var ticking = false;
	window.addEventListener('scroll', function(e) {
		last_known_scroll_position = window.scrollY;
		if (!ticking) {
			window.requestAnimationFrame(function() {
				handler(last_known_scroll_position);
				ticking = false;
			});
		}
		ticking = true;
	});
})(function(a){
	if(a > 200 && !isMobile){
		arrowUp.style.display != 'block' && fadeIn(arrowUp)
	}
	else{
		fadeOut(arrowUp)
	}
})
*/
/*=====  End of ARROW UP  ======*/

/*==============================
=            SLIDER            =
==============================*/
var slider = document.querySelector('.slider');
var slider_ = new Flickity( slider , {
		imagesLoaded: true,
		autoPlay: 10000,
		prevNextButtons: false
	});

window.addEventListener( 'load', function() {
  slider_.resize();
});
/*=====  End of SLIDER  ======*/